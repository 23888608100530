import { getOrderHistory } from '@api/order'
import { TOrder } from '@components/types'
import { Drawer, Steps, message } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'

type Props = {
    showViewDetails?: boolean,
    setShowViewDetails?: Function,
    order?: TOrder
}

const Ordertracker = ({ showViewDetails, setShowViewDetails, order }: Props) => {
    const [orderHistoryOptions, setOrderHistoryOptions] = useState([])
    const [isRefresh, setIsRefresh] = useState(false)

    const fetchOrderHistory = (refresh: boolean = false) => {
        getOrderHistory(order?.id)?.then((res: any) => {
            let temp = res.map((item: any) => {
                return {
                    title: <h2 className='text-neutral-900 font-medium'>{item?.title}</h2>,
                    description: <><p className='text-neutral-600'>{item?.message}</p><p className='text-neutral-500'>{moment(item?.added_on)?.format("DD/MM/YYYY, hh:mm a")}</p></>,
                    category: item?.category,
                }
            })
            setOrderHistoryOptions(temp.reverse())
            if (refresh) {
                message?.success("Refresh successfully!")
            }
        })
    }

    useEffect(() => {
        if (showViewDetails) {
            fetchOrderHistory()
        }
    }, [showViewDetails])


    return (
        <>
            <Drawer placement="right" headerStyle={{ borderBottom: "none" }} onClose={() => setShowViewDetails(false)} closable={false} open={showViewDetails}>
                <div className="flex flex-col justify-between grow h-full">
                    <div className="flex flex-col gap-4 font-manrope md:text-base text-sm">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <h2 className="font-bold font-manrope text-xl text-neutral-700">Track Order</h2>
                                <button
                                    onClick={() => fetchOrderHistory(true)}
                                    className="text-xs border-1 p-1 px-2 cursor-pointer border-brand-100 text-brand bg-brand-100 rounded-lg font-semibold">Refresh</button>
                            </div>
                            <button onClick={() => { setShowViewDetails(false) }}><MdClose /></button>
                        </div>
                        <div className="flex flex-col gap-4 grow h-full justify-between">
                            <Steps
                                current={orderHistoryOptions?.length - 1 || 1}
                                className='text-superone'
                                direction="vertical"
                                items={orderHistoryOptions}
                            />
                        </div>
                    </div>
                </div>
            </Drawer >

        </>
    )
}

export default Ordertracker