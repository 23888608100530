import { getUserLocation } from "@api/visitors";
import { TGeoLocation } from "@components/types";
import { message } from "antd";
import Cookies from "js-cookie";
let geoOptions = {
    timeout: 10 * 1000,
    enableHighAccuracy: true,
};

let defaultLocation = { "last_updated": 0, "latitude": 0, "longitude": 0 };

let setCityAndState = function (position: GeolocationPosition) {
    let location = getUserLocation(position?.coords?.longitude, position?.coords?.latitude).then(
        (currentLocation) => {
            if (currentLocation?.city?.name && currentLocation?.state?.name) {
                const myLocation = {
                    Location: {
                        name: `${currentLocation?.city?.name}, ${currentLocation?.state?.name}`,
                    },
                };
                localStorage.setItem('location', JSON.stringify(myLocation));
                Cookies.set('location', JSON.stringify(myLocation));
                Cookies.set('longitude', JSON.stringify(position?.coords?.longitude));
                Cookies.set('latitude', JSON.stringify(position?.coords?.latitude));
            }
        }
    );
    return location
}

let geoSuccess = function (position: GeolocationPosition) {
    let updatedLocation = { "last_updated": Date.now(), "latitude": position.coords.latitude, "longitude": position.coords.longitude }
    setCityAndState(position);
    localStorage.setItem("geoposition", JSON.stringify(updatedLocation));
    return updatedLocation
};

let geoError = function (error: any) {
    console?.debug('Error occurred. Error code: ' + error.code);
    if (error?.code === 1) {
        message?.error('Error occurred: User denied location access')
    } else {
        message?.error('Error occurred:' + error?.message)
    }
    if (localStorage.getItem("geoposition") === null) {
        localStorage.setItem("geoposition", JSON.stringify(defaultLocation));
    }
    // error.code can be:
    //   0: unknown error
    //   1: permission denied
    //   2: position unavailable (error response from location provider)
    //   3: timed out
};


export const getLocationPromise = (): Promise<GeolocationPosition> => {
    return new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            position => { resolve(position) },
            error => { reject(error) }
        )
    }).catch(error => error)
}

export const getLocation = async (): Promise<TGeoLocation> => {
    const location = await getLocationPromise();
    console.debug(location);
    if ("code" in location) {
        geoError(location);
        return JSON.parse(localStorage.getItem("geoposition"));
    }
    return geoSuccess(location);
}
