import React from "react";
import Image from "next/image";
import Link from "next/link";
import { Star } from "@components/star";
import { TProduct } from "@components/types";
import {
    getFirst,
    getImageObject,
    getLength,
    getSafeUrl,
    humanizeCurrency,
    sanityIoImageLoader,
} from "@core/utils";
import { AddToCart } from "@components/addtocart";

type Props = {
    product: TProduct;
    loading?: "lazy" | "eager"
};
export { RichCardV2 } from "./v2";
export const RichCard: React.FC<Props> = ({ product, loading }) => {
    return (
        <div className="relative flex-shrink-0 w-44 md:h-88 h-84 bg-neutral-50 border border-solid border-neutral-200 rounded-lg overflow-hidden">
            {product.tag && (
                <div className="absolute top-3 right-2 z-10 w-max rounded font-medium text-neutral-50 bg-brand-900 py-0.5 px-2 select-none uppercase">
                    {product.tag}
                </div>
            )}
            <div className="w-full h-full text-center flex flex-col">
                <div className="flex justify-center h-44">
                    <Link href={`/product/${product.id}/${getSafeUrl(product.name)}`}>
                        <Image
                            loader={sanityIoImageLoader}
                            src={getImageObject(product.images)?.url}
                            alt={getImageObject(product.images)?.description || product.name || 'Product'}
                            width="176"
                            height="176"
                            className="flex items-center justify-center h-44 object-cover aspect-square"
                            loading={loading}
                        />
                    </Link>
                </div>
                <div className="flex flex-col justify-between p-2 h-full">
                    <div className="leading-tight font-medium font-simple overflow-hidden min-h-8 md:min-h-12 pb-2">
                        <Link href={`/product/${product.id}/${getSafeUrl(product.name)}`}>
                            <p className="md:text-base text-sm text-neutral-700 text-left break-words line-clamp-2 leading-tight font-semibold font-manrope">
                                {product.name}
                            </p>
                        </Link>
                    </div>
                    <>
                        {product?.stats?.rating_overall ? (
                            <Star rating={product?.stats?.rating_overall} />
                        ) : (
                            <div className="flex flex-row gap-1 justify-between items-center text-neutral-500 text-sm">
                                No Reviews
                            </div>
                        )}
                        <div className="flex flex-col gap-2 justify-between w-full pt-1">
                            {getLength(product?.inventories) > 0 ? (
                                <div className="flex flex-col items-start">
                                    <span className="text-sm md:text-base text-neutral-800 font-manrope font-semibold">
                                        {humanizeCurrency(getFirst(product?.inventories)?.price) || humanizeCurrency(getFirst(product?.inventories)?.mrp)}
                                    </span>
                                    {getFirst(product?.inventories)?.price !== getFirst(product?.inventories)?.mrp && (
                                        <span className="text-xs text-neutral-600 font-manrope font-medium line-through">
                                            {humanizeCurrency(getFirst(product?.inventories)?.mrp || product.mrp)}
                                        </span>
                                    )}
                                </div>
                            ) : (
                                <div className="font-manrope text-sm text-error-400 font-semibold">Unavailable</div>
                            )}

                            {product?.inventories?.length != 0 ? (
                                <div className="w-full align-bottom">
                                    <AddToCart
                                        addToCartBag={false}
                                        product={product}
                                        inventory={getFirst(product?.inventories)}
                                    ></AddToCart>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
};
